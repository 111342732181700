import axios from "axios";
import swal from "sweetalert2";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

export default ({ app, store, redirect, $config: { apiUrl } }) => {
  axios.defaults.baseURL = apiUrl;
  axios.defaults.timeout = 20000;

  if (process.server) {
    return;
  }

  // Request interceptor
  axios.interceptors.request.use(request => {
    request.baseURL = apiUrl;

    const token = store.getters["auth/token"];

    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }

    const locale = store.getters["lang/locale"];
    if (locale) {
      request.headers.common["Accept-Language"] = locale;
    }

    return request;
  });

  // Response interceptor
  axios.interceptors.response.use(
    response => response,
    error => {
      const { status } = error.response || {};

      if (status === 429) {
        swal
          .fire({
            type: "error",
            title: app.i18n.t("too-many-requests"),
            text: app.i18n.t("too-many-requests-description"),
            reverseButtons: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 61000,
            timerProgressBar: true,
            showCancelButton: false
          })
          .then(() => {
            redirect("/shop/dashboard");
          });
      }

      if (status === 402) {
        store.commit("shop/SET_SHOW_UPGRADE_SUBSCRIPTION_MODAL", true);
        // Returning simulated error response
        return Promise.reject({ response: { data: { errors: {} } } });
      }

      if (status === 401 && store.getters["auth/check"]) {
        swal
          .fire({
            type: "warning",
            title: app.i18n.t("session-expired"),
            text: app.i18n.t("session-expired-description"),
            reverseButtons: true,
            confirmButtonText: "Login",
            showCancelButton: false
          })
          .then(() => {
            store.commit("auth/LOGOUT");

            redirect({ name: "login" });
          });
      }

      return Promise.reject(error);
    }
  );
};
